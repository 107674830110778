<template>
  <div class="card">
    <div
      class="
        card-header
        border-0
        pt-6
        d-flex
        justify-content-between
        align-items-center
      "
    >
      <h1>{{ facilityData.name }}</h1>
    </div>
    <div class="formRow">
      <div>
        <el-form
          @submit.prevent="submit()"
          :model="form"
          :rules="rules"
          ref="formRef"
          class="form"
        >
          <div>
            <div class="row1">
              <el-form-item class="m-0" label="名稱">
                <el-date-picker
                  v-model="form.datePicker"
                  type="datetimerange"
                  range-separator="至"
                  start-placeholder="開始日期"
                  end-placeholder="結束日期"
                  @change="changeDatePicker()"
                >
                </el-date-picker>
              </el-form-item>
              <div class="remainingText">
                剩餘可保留數量 : {{ timeRangeCanReserve }}
              </div>
            </div>
            <div class="mt-5 row2">
              <div>
                <el-checkbox class="checkbox" v-model="isDesignated"
                  >指定座位</el-checkbox
                >
                <div class="select">
                  <el-form-item
                    v-if="isDesignated"
                    class="m-0"
                    label="指定座位"
                    prop="targets"
                  >
                    <el-select
                      v-model="form.targets"
                      multiple
                      placeholder="請選擇座位"
                      :disabled="!isSelectedTime"
                    >
                      <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item
                    v-else
                    class="m-0"
                    label="保留座位數"
                    prop="targetCount"
                  >
                    <el-input-number
                      :disabled="!isSelectedTime"
                      v-model="form.targetCount"
                      :min="1"
                      :max="timeRangeCanReserve"
                    ></el-input-number>
                  </el-form-item>
                </div>
              </div>
            </div>
            <el-form-item class="mt-10" label="保留原因" style="width: 500px">
              <el-input
                :disabled="!isSelectedTime"
                type="textarea"
                v-model="form.remark"
              ></el-input>
            </el-form-item>
          </div>
          <div class="d-flex justify-content-end">
            <button
              :disabled="!isSelectedTime"
              class="btn btn-success"
              type="submit"
            >
              送出
            </button>
          </div>
        </el-form>
      </div>
    </div>
    <div>
      <div class="p-15">
        <h1>期間已有票券 :</h1>
        <Datatable
          :table-data="tableData"
          :table-header="tableHeader"
          :enable-items-per-page-dropdown="true"
          :openTextEnd="false"
          :striped="true"
        >
          <template v-slot:cell-seatName="{ row: tableData }">
            {{ tableData.seatName }}
          </template>
          <template v-slot:cell-beginTime="{ row: tableData }">
            {{ formatTime(tableData.beginTime) }}
          </template>
          <template v-slot:cell-endTime="{ row: tableData }">
            {{ formatTime(tableData.endTime) }}
          </template>
          <template v-slot:cell-userName="{ row: tableData }">
            {{ tableData.userName }}
          </template>
          <template v-slot:cell-userEmail="{ row: tableData }">
            {{ tableData.userEmail }}
          </template>
        </Datatable>
      </div>
    </div>
  </div>
</template>

<script lang='ts'>
import { defineComponent, reactive, ref } from "vue";
import {
  getFacility,
  getTimeRangeReserve,
} from "@/core/services/api/facilities";
import { getFacilityUnitByFacility } from "@/core/services/api/facilityUnits";
import { getTokensByFacility } from "@/core/services/api/tokens";
import { getUserByGuid } from "@/core/services/api/user";
import { addSpecialReserves } from "@/core/services/api/tokens";
import { useRouter, useRoute } from "vue-router";
import Datatable from "@/components/kt-datatable/Datatable.vue";
import localstorage from "@/core/services/LocalstorageService";
import { getLocalTimeString, getUtcTimeString } from "@/core/utils/timeString";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  components: {
    Datatable,
  },
  setup() {
    const formRef = ref<null | HTMLFormElement>(null);
    const isSelectedTime = ref(false);
    const isDesignated = ref(false);
    const options: any = reactive([]);
    let timeRangeCanReserve = ref<any>("未選擇時間");
    const tableData: any = reactive([]);
    const tableHeader = [
      { key: "seatName", name: "座位名稱", sortable: true },
      { key: "beginTime", name: "起始時間", sortable: true },
      { key: "endTime", name: "結束時間", sortable: true },
      { key: "userName", name: "使用者", sortable: true },
      { key: "userEmail", name: "使用者Email", sortable: true },
    ];
    const currentSpaceGuid = localstorage.getCurrentSpaces()?.space.guid;
    const allFacilityUnit: any = reactive([]);
    const route = useRoute();
    const router = useRouter();
    const facilityGuid: any = route.params.facilityGuid;
    const facilityData: any = reactive({});
    const rules = reactive({
      targetCount: [
        {
          required: true,
          message: "請填寫保留座位數",
          trigger: "blur",
        },
      ],
      targets: [
        {
          required: true,
          message: "請選擇座位",
          trigger: "blur",
        },
      ],
    });
    const form:any = reactive({
      datePicker: [],
      targetCount: 0,
      targets: [],
      remark: "",
    });

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value!.validate(async (valid) => {
        if (valid) {
          Swal.fire({
            title: "您確定要送出特殊保留嗎?",
            showDenyButton: true,
            showCancelButton: true,
            confirmButtonText: `確定`,
            denyButtonText: `取消`,
          }).then(async (result) => {
            if (result.isConfirmed) {
              let request: any = [];
              if (isDesignated.value) {
                for (const o of form.targets) {
                  request.push({
                    facility: facilityGuid,
                    facilityUnit: o,
                    beginTime: form.datePicker[0],
                    endTime: form.datePicker[1],
                    remark: form.remark,
                  });
                }
              } else {
                for (let index = 0; index < form.targetCount; index++) {
                  request.push({
                    facility: facilityGuid,
                    facilityUnit: "",
                    beginTime: form.datePicker[0],
                    endTime: form.datePicker[1],
                    remark: form.remark,
                  });
                }
              }
              await addSpecialReserves(request);
              router.go(-1)
            }
          });
        }
      });
    };

    const setFacilityData = async () => {
      let response: any = await getFacility(facilityGuid);
      Object.assign(facilityData, response.facility);
    };

    const timeRangeTickets: any = reactive([]);
    // 查詢期間內的票券
    const selectTicketsInMeantime = async () => {
      // let beginAndEnd = this.getBeginAndEnd();
      let request = {
        facility: facilityGuid,
        beginTime: getUtcTimeString(form.datePicker[0]),
        endTime: getUtcTimeString(form.datePicker[1]),
      };
      let tickets = await getTokensByFacility(request);
      timeRangeTickets.splice(0, timeRangeTickets.length, ...tickets);
    };

    // 取得票券中已使用座位
    const getUsingFacilityUnit = () => {
      let tickets = timeRangeTickets;
      tickets = tickets.filter((o) => o.facilityUnit !== "");
      let ticketsFacilityUnit = tickets.map((o) => o.facilityUnit);
      return Array.from(new Set(ticketsFacilityUnit));
    };

    // 取得所有座位
    const getAllFacilityUnit = async () => {
      let facilityUnits = await getFacilityUnitByFacility(
        currentSpaceGuid!,
        facilityGuid
      );

      allFacilityUnit.splice(0, allFacilityUnit.length, ...facilityUnits);
    };

    // 取得座位名稱
    const getFacilityUnitName = (guid) => {
      let facilityUnitName = allFacilityUnit.filter((o) => o.guid == guid)[0]
        .name;
      return facilityUnitName;
    };

    // 設置期間票券列表
    const setTicketsTable = async () => {
      let tickets = JSON.parse(JSON.stringify(timeRangeTickets));
      tickets = tickets.filter(
        (o) =>
          !(
            o.mode == "SubDistribute" ||
            o.mode == "SubInvite" ||
            o.mode == "SpecialAccess" ||
            o.status == "Canceled"
          )
      );
      for (const o of tickets) {
        o.seatName =
          o.facilityUnit == "" ? "未指定" : getFacilityUnitName(o.facilityUnit);
        let userGuid = {
          user: o.from,
        };
        let userdata = await getUserByGuid(userGuid);
        o.userName = userdata.user.name;
        o.userEmail = userdata.user.email;
      }
      

      tableData.splice(0, tableData.length, ...tickets);
    };

    const formatTime = (time) => {
      return getLocalTimeString(time);
    };

    const judgeCanAddReserve = () => {
      if (
        typeof timeRangeCanReserve.value == "string" ||
        timeRangeCanReserve.value <= 0
      ) {
        isSelectedTime.value = false;
      } else {
        isSelectedTime.value = true;
      }
    };

    const changeDatePicker = async () => {
      if (!form.datePicker) {
        isSelectedTime.value = false;
        tableData.splice(0);
        timeRangeCanReserve.value = "未選擇時間";
      } else {
        await selectTicketsInMeantime();
        await setTicketsTable();
        await meantimeCanReserve();
        await setFacilityUnitOptions();
        judgeCanAddReserve();
      }
    };

    const meantimeCanReserve = async () => {
      let request = {
        facilities: [facilityGuid],
        beginTime: getUtcTimeString(form.datePicker[0]),
        endTime: getUtcTimeString(form.datePicker[1]),
        tokens: [],
      };
      let timeRangeReserve = (await getTimeRangeReserve(request))[0].timeRanges;
      let minQuota = 0;
      if (timeRangeReserve.length > 0) {
        let quotas = timeRangeReserve.map((o) => o.quota);
        minQuota = Math.min(...quotas);
      }
      timeRangeCanReserve.value = minQuota;
    };

    // 設置座位選單
    const setFacilityUnitOptions = async () => {
      let facilityUnitOptions: any = [];
      let filterFacilityUnit = JSON.parse(JSON.stringify(allFacilityUnit));
      let ticketsFacilityUnit = getUsingFacilityUnit();
      filterFacilityUnit = filterFacilityUnit.filter(
        (o) => !ticketsFacilityUnit.includes(o.guid)
      );
      filterFacilityUnit.forEach((o) => {
        facilityUnitOptions.push({
          value: o.guid,
          label: o.name,
        });
      });
      options.splice(0, options.length, ...facilityUnitOptions);
    };

    const init = async () => {
      await setFacilityData();
      await getAllFacilityUnit();
    };
    init();

    return {
      form,
      rules,
      submit,
      facilityData,
      tableData,
      tableHeader,
      formatTime,
      changeDatePicker,
      timeRangeCanReserve,
      isDesignated,
      options,
      isSelectedTime,
      formRef,
    };
  },
});
</script>

<style scoped>
.formRow {
  display: flex;
  justify-content: start;
  margin-left: 30px;
}

.formRow > div {
  background: #f9f4f4;
  padding: 20px;
  border-radius: 5px;
}

.formRow .row1 {
  display: flex;
  align-items: center;
}
.formRow .row2 {
  display: flex;
  align-items: center;
}

.formRow .row2 select {
  width: 200px;
}

.formRow .remainingText {
  margin-left: 20px;
  font-size: 18px;
  font-weight: 800;
}

.formRow .checkbox {
  font-size: 18px;
  font-weight: 800;
}
</style>